import Service from "../../Service";

const resource = "shipment/"

export default {

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(obj, requestID) {
        console.log("datos en el servicio", obj)
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    getSaleOrder(requestID, DocNum) {
        return Service.post(resource + "getSaleOrder", "", {
            params: { requestID: requestID, DocNum: DocNum },
        });
    },


    getNotifier(requestID, EspID) {
        return Service.post(resource + "getNotifier", "", {
            params: { requestID: requestID, EspID: EspID },
        });
    },

    downloadtemplate(EspID, requestID) {
        return Service.post(resource + "donwloadLetterTemperature", EspID, {
            params: { requestID },
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },

    downloadInstruction(EspID, requestID) {
        console.log('probando el servicio', EspID)
        return Service.post(resource + "downloadInstruction", EspID, {
            params: { requestID },
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    }

};