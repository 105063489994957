<template>
  <div>
    <Shipment label="Embarque"></Shipment>
  </div>
</template>

<script>

import Shipment from './Shipment.vue'

export default {
   name: "ShipmentG",
  components: { Shipment },
  props: {},
  data: () => ({}),
}
</script>