<template>
  <v-container>
    <div>
      <s-crud
        add
        :filter="filter"
        :config="config"
        remove
        no-search
        title="Embarque"
        addNoFunction
        @add="add()"
        @rowSelected="rowSelected($event)"
        ref="crudemb"
      >
        <template v-slot:filter>
          <v-col>
            <v-row justify="center" style="padding-bottom: 20px">
              <v-col style="padding-top: 25px" cols="12" lg="3" sm="3" md="3">
                <v-btn
                  @click="dialogLetterTemperature = true"
                  dark
                  rounded
                  small
                  color="info"
                  >Registrar Datos Temperatura</v-btn
                >
              </v-col>
              <v-col style="padding-top: 25px" cols="12" lg="3" sm="3" md="3">
                <v-btn
                  @click="openEmbarque()"
                  dark
                  small
                  rounded
                  color="warning"
                  >Modificar registro</v-btn
                >
              </v-col>
              <v-col cols="12" lg="3" sm="3" md="3">
                <s-date
                  v-model="filter.BeginDate"
                  label="Fecha inicio"
                ></s-date>
              </v-col>
              <v-col cols="12" lg="3" sm="3" md="3">
                <s-date v-model="filter.EndDate" label="Fecha fin"></s-date>
              </v-col>
            </v-row>
          </v-col>
        </template>

        <template v-slot:documento="{ row }">
          <v-btn icon color="primary" @click="showDocuments">
            <v-icon>fas fa-eye</v-icon>
          </v-btn>
        </template>
        <template v-slot:shipInstruc="{ row }">
          <v-btn icon color="red darken-4">
            <v-icon
              @click="downloadInstruction(row)"
              class="fas fa-file-download"
            ></v-icon>
          </v-btn>
        </template>
      </s-crud>
    </div>

    <!-- ventana dialog para registrar  -->
    <v-dialog
      v-model="dialogRegister"
      v-if="dialogRegister"
      fullscreen
      hide-overlay
      persistent
    >
      <v-card>
        <s-toolbar
          label="EMBARQUE"
          color="light-blue darken-3"
          dark
          close
          @close="dialogRegister = false"
        >
        </s-toolbar>
        <shipment-register
          :data="Shipment"
          :edit="edit"
          @closeModal="closeModalRegister"
        ></shipment-register>
      </v-card>
    </v-dialog>

    <!--ventana para ver los documentos-->
    <v-dialog v-model="dialogDocument">
      <s-toolbar
        label="Descarga Documentos"
        color="light-blue darken-3"
        dark
        close
        @close="dialogDocument = false"
      >
      </s-toolbar>
      <shipment-document :Shipment="Shipment"></shipment-document>
    </v-dialog>

    <!-- ventana para ingresar datos para la carte de temperatura -->
    <v-dialog v-model="dialogLetterTemperature">
      <s-toolbar
        label="Datos Carta Temperatura"
        color="light-blue darken-3"
        dark
        close
        @close="dialogLetterTemperature = false"
      >
      </s-toolbar>
      <shipment-letter-temperature
        :Shipment="Shipment"
      ></shipment-letter-temperature>
    </v-dialog>
  </v-container>
</template>

<script>
import _sShipment from "@/services/ExternalTrade/Shipment/ShipmentService";
import ShipmentRegister from "@/views/ExternalTrade/Shipment/ShipmentRegister";
import SDate from "../../../components/Utils/SDate.vue";
import ShipmentDocument from "./ShipmentDocument.vue";
import ShipmentLetterTemperature from "./ShipmentLetterTemperature.vue";
import _sHelper from "@/services/HelperService";

export default {
  components: {
    SDate,
    ShipmentRegister,
    ShipmentDocument,
    ShipmentLetterTemperature,
  },
  data: () => ({
    dialogDocument: false,
    items: [],
    dialogRegister: false,
    filter: {
      uploadDate: "",
      shippingDate: "",
      BeginDate: null,
      EndDate: null,
    },
    config: {
      service: _sShipment,
      model: {
        EspID: "ID",
        Actions: "",
        EspShippingDate: "date",
        documento: "",
        shipInstruc: "",
      },
      headers: [
        { text: "Documentos", value: "documento", width: 90 },
        { text: "I embarque", value: "shipInstruc", width: 80 },
        { text: "Contenedor", value: "EspContainer", width: 80 },
        {
          text: "R. Social eXportador",
          value: "EspBusNameExporter",
          width: 20,
        },
        { text: "F. Embarque", value: "EspShippingDate", width: 100 },
        { text: "Direcc. Cliente", value: "EspAddressClient", width: 100},
        { text: "Precinto sunshine", value: "EspSealSunshine", width: 100},
        { text: "Precinto de Line", value: "EspSealLine", width: 100},
        { text: "Direcc. Exportador", value: "EspAddressExporter", width: 100},
        {
          text: "N° Telf Exportador",
          value: "EspTelfNumberExport",
          width: 100,
        },
        {
          text: "N° Telf Cliente",
          value: "EspTelfNumberClient",
          width: 100,
        },
        { text: "Orden venta", value: "EspSaleOrder", width: 100 },
        { text: "Puerto Carga", value: "EspPortLoading", width: 100 },
        {
          text: "Destino final",
          value: "EspFinalDestination",
          width: 100,
        },
        { text: "Contac Export", value: "EspContacExport", width: 100 },
        {
          text: "Puerto/Aeropuerto emba.",
          value: "EspPortShipment",
          width: 100,
        },
        { text: "Ruc Exportador", value: "EspRucExport", width: 100 },
        { text: "PO Cliente", value: "EspPoClient", width: 100 },
        {
          text: "Condición Flete",
          value: "EspFreightCondition",
          width: 100,
        },
        { text: "Modalida Pago", value: "EspPaymentMethod", width: 100 },
        {
          text: "Entidad Bancaria",
          value: "EspBankingEntity",
          width: 100,
        },
        { text: "VAT / TAX", value: "EspConsigneeVatTax", width: 100 },
        {
          text: "Telf Consignatario",
          value: "EspTelNumConsignee",
          width: 100,
        },
        {
          text: "Contac. Consignatario",
          value: "EspContConsignee",
          width: 100,
        },
        {
          text: "Email Consignatario",
          value: "EspEmailConsignee",
          width: 100,
        },
        { text: "Hora carga", value: "EspHourLoad", width: 100 },
        {
          text: "Terminal Ingreso",
          value: "EspEntryTerminal",
          width: 100,
        },
        { text: "N° Ubigeo", value: "EspUbigeoNumber", width: 100 },
        { text: "Uso Producto", value: "EspProductUse", width: 100 },
        { text: "DrawBack", value: "EspDrawBack", width: 100 },
        { text: "Emisión", value: "EspIssue", width: 100 },
        {
          text: "Descrip Producto",
          value: "EspProductDescription",
          width: 100,
        },
        { text: "Uso Producto", value: "EspProductUse", width: 100 },
      ],
    },
    Shipment: null,
    dialogLetterTemperature: false,
    edit: false,
  }),

  methods: {
    showDocuments() {
      this.dialogDocument = true;
    },

    downloadInstruction(item) {
      this.$fun
        .alert("¿Seguro de descargar Instruccion?", "question")
        .then((r) => {
          if (r.value) {
            console.log("datos que me envian dd", item);
            _sShipment
              .downloadInstruction(item, this.$fun.getUserID())
              .then((r) => {
                this.$fun.downloadFile(
                  r.data,
                  this.$const.TypeFile.PDF,
                  "Compromiso"
                );
              });
          }
        });
    },

    rowSelected(rows) {
      this.Shipment = rows[0];
      Object.assign({}, this.Shipment);
      console.log(this.Shipment);
    },

    save(item) {
      item.save();
    },

    closeModalRegister() {
      this.dialogRegister = false;
      this.$refs.crudemb.refresh();
    },

    add() {
      this.dialogRegister = true;
      this.edit = false;
    },

    openEmbarque() {
      this.dialogRegister = true;
      this.edit = true;
    },
  },
};
</script>
