<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="Shipments"
		>
			<template v-slot:item.LetterTemperature="{ item }">
				<v-btn
					color="primary"
					@click="downloadLetterTemperature(item)"
				>
					Descargar
    			</v-btn>
			</template>

			<template v-slot:item.EspFileBk="{ item }">
				<v-btn
					color="primary"
					@click="downloadEspFileBk(item.EspFileBk)"
				>
					Descargar
				</v-btn>
			</template>

			<template v-slot:item.EspFileBl="{ item }">
				<v-btn
					color="primary"
					@click="downloadEspFileBl(item.EspFileBl)"
				>
					Descargar
				</v-btn>
			</template>

			<template v-slot:item.EspFileDam="{ item }">
				<v-btn
					color="primary"
					@click="EspFileDam(item.EspFileDam)"
				>
					Descargar
				</v-btn>
			</template>
		</v-data-table>

	</div>
</template>

<script>
	import _sHelper from "@/services/HelperService";
	import _sShipment from "@/services/ExternalTrade/Shipment/ShipmentService";

	export default {
		props: {
			Shipment: {
				type: Object,
				default: null
			},
		},

		data() {
			return {
				headers: [
					{text: 'Carta Temperatura', value: 'LetterTemperature'},
					{text: 'BK', value: 'EspFileBk'},
					{text: 'BL', value: 'EspFileBl'},
					{text: 'DAM', value: 'EspFileDam'}
				],
				Shipments: []
			}
		},

		methods: {
			downloadLetterTemperature(EspID) 
			{
				// alert(EspID);   
				this.$fun.alert("¿Seguro de descargar Carta Temperatura?", "question")
				.then(r => {
					if(r.value){
						_sShipment.downloadtemplate(EspID, this.$fun.getUserID())
						.then((r) => {
							this.$fun.downloadFile(
								r.data,
								this.$const.TypeFile.PDF,
								"Compromiso"
							);
						});
					}
				})
			},

			downloadEspFileBk(EspFileBk)
			{
				this.$fun.alert("¿Seguro de descargar Bk?", "question")
				.then(r => {
					if(r.value){
						_sHelper.getfile(1, this.$fun.getUserID(), EspFileBk, 6).then((r) => {
							const url = window.URL.createObjectURL(new Blob([r.data]));
							const link = document.createElement("a");
							link.href = url;
							link.setAttribute("download", EspFileBk);
							document.body.appendChild(link);
							link.click();
						});
					}
				})
			},

			downloadEspFileBl(EspFileBl)
			{
				this.$fun.alert("¿Seguro de descargar Bl?", "question")
				.then(r => {
					if(r.value){
						_sHelper.getfile(1, this.$fun.getUserID(), EspFileBl, 7).then((r) => {
							const url = window.URL.createObjectURL(new Blob([r.data]));
							const link = document.createElement("a");
							link.href = url;
							link.setAttribute("download", EspFileBl);
							document.body.appendChild(link);
							link.click();
						});
					}
				})
			},

			EspFileDam(EspFileDam)
			{
				this.$fun.alert("¿Seguro de descargar DAM?", "question")
				.then(r => {
					if(r.value){
						_sHelper.getfile(1, this.$fun.getUserID(), EspFileDam, 8).then((r) => {
							const url = window.URL.createObjectURL(new Blob([r.data]));
							const link = document.createElement("a");
							link.href = url;
							link.setAttribute("download", EspFileDam);
							document.body.appendChild(link);
							link.click();
						});
					}
				})
			}
		},

		watch: {
			Shipment() {
				console.log('shipment ', this.Shipment);
			}
		},

		created () {
			this.Shipments.push(this.Shipment);
		},
	}
</script>