<template>
	<div>
		<v-card>
			<v-container>
				<v-row>
					<v-col cols="12" lg="3" md="6" sm="12">
						<s-switch v-model="item.EstHumidity" label="Humedad"></s-switch>
					</v-col>
					<v-col cols="12" lg="3" md="6" sm="12">
						<s-select-definition v-model="item.TypeVentilation" :def="1305" label="Tipo Ventilación"></s-select-definition>
					</v-col>
					<v-col cols="12" lg="3" md="6" sm="12">
						<s-switch v-model="item.EspColdTreatment" label="Trat. Enfriamiento"></s-switch>
					</v-col>
					<v-col cols="12" lg="3" md="6" sm="12">
						<s-switch v-model="item.EspControlAtmosphere" label="Ctrl. Atmosferico"></s-switch>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" lg="3" md="6" sm="12">
						<s-text v-model="item.EspTechnology" label="Técnología"></s-text>
					</v-col>
					<v-col cols="12" lg="3" md="6" sm="12">
						<s-text number v-model="item.EspCo2" label="CO2"></s-text>
					</v-col>
					<v-col cols="12" lg="3" md="6" sm="12">
						<s-text number v-model="item.EspO2" label="02"></s-text>
					</v-col>
					<v-col cols="12" lg="3" md="6" sm="12">
						<v-btn
							class="mt-3"
							color="primary"
							block
							@click="save"
						>
							GUARDAR
							
						</v-btn>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>

	export default {
		components: {  },
		props: {
			Shipment: {
				type: Object,
				default: null
			},
		},

		data() {
			return {
				item: []
			}
		},

		methods: {
			save()
			{
				this.Shipment.EstHumidity = this.item.EstHumidity;
				this.Shipment.TypeVentilation = this.item.TypeVentilation;
				this.Shipment.EstColdTreatment = this.item.EstColdTreatment;
				this.Shipment.EstControlAtmosphere = this.item.EstControlAtmosphere;
				this.Shipment.EstTechnology = this.item.EstTechnology;
				this.Shipment.EstCo2 = this.item.EstCo2;
				this.Shipment.EstO2 = this.item.EstO2;
			}
		},
	}
</script>