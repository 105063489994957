<template>
  <div style="padding: 25px 20px 15px">
    <v-row>
      <v-col cols="8">
        <v-row>
          <v-col cols="4" class="s-col-form">
            <s-text
              v-model="DocNum"
              @keyupEnter="getOrder()"
              label="N° Orden venta"
            >
              <template v-slot:append>
                <v-icon
                  style="font-size: 12px; padding-top: 5px"
                  class="fas fa-search"
                >
                </v-icon>
              </template>
            </s-text>
          </v-col>
        </v-row>
        <v-row style="padding-bottom: 25px; padding-top: 5px">
          <v-col cols="3" class="s-col-form">
            <s-date
              v-model="items.EspShippingDate"
              label="Fecha embarque">
            </s-date>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <sselect-seal 
              label="Precinto sunshine"
              v-model="items.EspSealSunshine">
              
            </sselect-seal>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspSealLine"
              v-model="items.EspSealLine"
              label="Precinto de Linea"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              number
              ref="EspTemperature"
              v-model="items.EspTemperature"
              label="Temperatura °C"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspEntryTerminal"
              v-model="items.EspEntryTerminal"
              label="Terminal de ingreso"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspUbigeoNumber"
              v-model="items.EspUbigeoNumber"
              label="N° ubigueo"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-select-type-certification
              full
              autofocus
              v-model="items.TypeCertification"
              label="Tipo Certificación"
            ></s-select-type-certification>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-time v-model="items.EspHourLoad" label="Hora de carga"></s-time>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspPortShipment"
              v-model="items.EspPortShipment"
              label="Puert/Aeropuert embarque"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspIssue"
              v-model="items.EspIssue"
              label="Emisión"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspFinalDestination"
              v-model="items.EspFinalDestination"
              label="Destino final"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspProductUse"
              v-model="items.EspProductUse"
              label="Uso final del producto"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspConsigneeVatTax"
              v-model="items.EspConsigneeVatTax"
              label="VAT/TAX Consignatario"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspTelNumConsignee"
              v-model="items.EspTelNumConsignee"
              label="Telf. Consignatario"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspContConsignee"
              v-model="items.EspContConsignee"
              label="Contact. Consignatario"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspEmailConsignee"
              v-model="items.EspEmailConsignee"
              label="Email Consignatario"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspProductDescription"
              v-model="items.EspProductDescription"
              label="Descripción del producto"
            ></s-text>
          </v-col>
          <v-col cols="3" class="s-col-form">
            <s-text
              ref="EspTariffHeading"
              v-model="items.EspTariffHeading"
              label="Partida arancelaria"
            ></s-text>
          </v-col>
        </v-row>
        <s-toolbar label="Datos orden venta" color="light-blue darken-3" dark>
        </s-toolbar>
        <v-data-table
          item-key="DocNum"
          height="120px"
          style="padding-bottom: 20px"
          :headers="headerData"
          :items="itemsData"
          :items-per-page="-1"
          hide-default-footer
        >
        </v-data-table>
        <v-row justify="center" align="center">
          <v-col align="center" cols="12" lg="4" md="4">
            <v-btn
              @click="save()"
              color="info"
              dark
              style="width: 100%; text-align: center"
              >Grabar</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col cols="4">
        <v-row style="padding-bottom: 0px">
          <v-col cols="12" class="s-col-form">
            <h4>Adjuntar archivos</h4>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-load label="subir archivo (BK)" v-model="fileBk"></s-load>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-load label="subir archivo (BL)" v-model="fileBl"></s-load>
          </v-col>
          <v-col cols="12" class="s-col-form">
            <s-load label="subir archivo (DAM)" v-model="fileDAM"></s-load>
          </v-col>
        </v-row>

        <v-row style="padding-bottom: 0px">
          <v-col cols="12" class="s-col-form">
            <h4>Datos del cliente</h4>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspAddressClient"
              v-model="items.EspAddressClient"
              label="Direccion Cliente"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspTelfNumberClient"
              v-model="items.EspTelfNumberClient"
              label="Nª Tef Cliente"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspFreightCondition"
              v-model="items.EspFreightCondition"
              label="Condicion del flete"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspPaymentMethod"
              v-model="items.EspPaymentMethod"
              label="Modalidad de pago"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspBankingEntity"
              v-model="items.EspBankingEntity"
              label="Entidad bancaria"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspPoClient"
              v-model="items.EspPoClient"
              label="PO Cliente"
            ></s-text>
          </v-col>
        </v-row>
        <v-row style="padding-bottom: 10px">
          <v-col cols="12" class="s-col-form">
            <h4>Datos del Exportador</h4>
          </v-col>
          <v-col cols="12" class="s-col-form">
            <s-text
              ref="EspBusNameExporter"
              v-model="items.EspBusNameExporter"
              label="R.social exportador"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspTelfNumberExport"
              v-model="items.EspTelfNumberExport"
              label="Nª Tef Exportador"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspContacExport"
              v-model="items.EspContacExport"
              label="Contacto Exportador"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspRucExport"
              v-model="items.EspRucExport"
              label="Ruc Exportador"
            ></s-text>
          </v-col>
          <v-col cols="6" class="s-col-form">
            <s-text
              ref="EspAddressExporter"
              v-model="items.EspAddressExporter"
              label="Direccion exportador"
            ></s-text>
          </v-col>
        </v-row>
        <v-row justify="center" style="padding-bottom: 10px">
          <!-- <v-col cols="6" class="s-col-form">
            <v-btn dark color="light-green lighten-1">
              Agregar Detalles
            </v-btn>
          </v-col> -->
          <v-col cols="12" align="center" class="s-col-form">
            <v-btn
              @click="dialogNotifier = true"
              width="100%"
              dark
              color="light-green lighten-1"
            >
              Agregar Notificante
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- ventana modal para agregar notificante -->
    <v-dialog
      width="700px"
      persistent
      v-model="dialogNotifier"
      v-if="dialogNotifier"
    >
      <v-card height="500px">
        <s-toolbar
          close
          dark
          color="light-blue darken-3"
          @close="dialogNotifier = false"
          label="registro de notificante"
        >
        </s-toolbar>
        <v-container>
          <v-row justify="center">
            <v-col cols="12" class="s-col-form">
              <s-text
                v-model="itemsNotifiers.EsnNotifyBusinessName"
                label="Razòn Social"
              ></s-text>
            </v-col>
            <v-col cols="4" class="s-col-form">
              <s-text
                v-model="itemsNotifiers.EsnNotifyAddress"
                label="Direcciòn"
              ></s-text>
            </v-col>
            <v-col cols="4" class="s-col-form">
              <s-text
                v-model="itemsNotifiers.EsnVatTax"
                label="VAT / TAX ID"
              ></s-text>
            </v-col>
            <v-col cols="4" class="s-col-form">
              <s-text
                v-model="itemsNotifiers.EsnTelfNumber"
                label="Telefono"
              ></s-text>
            </v-col>
            <v-col cols="4" class="s-col-form">
              <s-text v-model="itemsNotifiers.EsnFax" label="Fax"></s-text>
            </v-col>
            <v-col cols="4" class="s-col-form">
              <s-text
                v-model="itemsNotifiers.EsnContac"
                label="Contacto"
              ></s-text>
            </v-col>
            <v-col cols="4" class="s-col-form">
              <s-text v-model="itemsNotifiers.EsnEmail" label="Email"></s-text>
            </v-col>
          </v-row>
          <v-row
            style="padding-top: 10px; padding-bottom: 20px"
            justify="center"
          >
            <v-col align="center" cols="12">
              <v-btn
                @click="addNotifier()"
                color="green darken-1"
                dark
                style="width: 100%; text-align: center"
                >Agregar</v-btn
              >
            </v-col>
          </v-row>
          <s-toolbar dark color="primary" label="Datos"></s-toolbar>
          <v-data-table
            item-key="Line"
            :headers="headerNotifier"
            :items="dataNotifiersAll"
          >
            <template v-slot:item.Actions="{ item }">
              <v-btn
                color="teal darken-1"
                dark
                x-small
                @click="removeItem(item)"
                block
              >
                <v-icon small>mdi-minus-circle-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import SselectSeal from "../../../components/ExternalTrade/SselectSeal.vue";
import SSelectDefinition from "../../../components/Utils/SSelectDefinition.vue";
import _sShipment from "../../../services/ExternalTrade/Shipment/ShipmentService";
import SSelectTypeCertification from "../../../components/Utils/Certification/sSelectTypeCertification.vue";
import _sHelper from "@/services/HelperService";

export default {
  props: {
    data: { required: true },
    edit: { type: Boolean, required: true },
  },
  components: { SSelectDefinition, SSelectTypeCertification ,SselectSeal},

  data() {
    return {
      itemsData: [],
      Validated: false,
      Actions: "",
      fileBk: [],
      fileBl: [],
      fileDAM: [],

      message: "",
      headerNotifier: [
        { text: "Acciones", value: "Actions", width: 100 },
        { text: "Item", value: "Line", width: 100 },
        {
          text: "Razón social",
          value: "EsnNotifyBusinessName",
          width: 100,
        },
        { text: "Dirección", value: "EsnNotifyAddress", width: 100 },
        { text: "VAT/TAX ID", value: "EsnVatTax", width: 100 },
        { text: "Telefono", value: "EsnTelfNumber", width: 100 },
        { text: "Fax", value: "EsnFax", width: 100 },
        { text: "Contacto", value: "EsnContac", width: 100 },
        { text: "Email", value: "EsnEmail", width: 100 },
      ],
      itemsNotifiers: {},
      dataNotifiersAll: [],
      items: {},
      dialogNotifier: false,
      DocNum: "",
      headerData: [
        { text: "N° Orden venta", value: "DocNum", width: 50 },
        { text: "N° Booking", value: "NumberBooking", width: 50 },
        { text: "Puerto Destino", value: "PortDestiny", width: 60 },
        { text: "Nave de Salida", value: "DepartureShip", width: 60 },
        { text: "Fecha de salida", value: "DepartureDate", width: 80 },
        { text: "Linea Naviera", value: "ShippingLine", width: 60 },
        {
          text: "Operador Losgistico",
          value: "losgisticOperator",
          width: 60,
        },
        { text: "Días credito", value: "CreditDays", width: 60 },
        {
          text: "Razon social consig",
          value: "ConsigneeCompanyName",
          width: 100,
        },
        {
          text: "Direccion consig",
          value: "ConsigneeAddress",
          width: 200,
        },
        { text: "Lugar de carga", value: "LoadingPlace", width: 60 },
        { text: "Fecha de carga", value: "UploadDate", width: 80 },
      ],

      itemsRemoved: [],
    };
  },

  created() {
    if (this.edit) {
      this.items = this.data;
      this.DocNum = this.items.DocNum;
      this.getOrder();
      this.getNotifier();
    }
  },

		methods: {
			getNotifier() {
				_sShipment
					.getNotifier(this.$fun.getUserID(), this.items.EspID)
					.then(r => {
						if (r.status == 200) {
							this.dataNotifiersAll = r.data;
							this.orderDetails();
						}
					});
			},

    getOrder() {
      console.log("valor ingresado", this.DocNum);
      _sShipment.getSaleOrder(this.$fun.getUserID(), this.DocNum).then((r) => {
        if (r.status == 200) {
          console.log("datos obtenidos", r.data);
          this.itemsData = r.data;
          this.itemsData.forEach((element) => {
            element.UploadDate = this.$moment(element.UploadDate).format(
              this.$const.FormatDateDB
            );
            element.DepartureDate = this.$moment(element.UploadDate).format(
              this.$const.FormatDateDB
            );
          });
        }
      });

      //this.isValidated();
    },

    save() {
      if (this.itemsData.length == 0) {
        this.$fun.alert("Porfavor debe consultar la orden de venta", "warning");
        return;
      }

      if (this.dataNotifiersAll.length == 0) {
        this.$fun.alert("Porfavor al menos agregue un notificador", "warning");
        return;
      }

      this.items.DocNum = this.itemsData[0].DocNum;
      this.items.DocEntry = this.itemsData[0].DocEntry;
      this.items.UsrCreateID = this.$fun.getUserID();

      this.items.EspFileBl = this.fileBl.name != null ? this.fileBl.name : "";
      this.items.EspFileBk = this.fileBk.name != null ? this.fileBk.name : "";
      this.items.EspFileDam =
        this.fileDAM.name != null ? this.fileDAM.name : "";

      this.items.dataNotifiers = [
        ...this.dataNotifiersAll,
        ...this.itemsRemoved,
      ];
      //this.items.dataNotifiers = this.dataNotifiersAll;

      console.log("Guardar", this.items);

      // this.$fun.alert("Seguro de guardar?", "question").then((r) => {
      //   if (r.value) {
      //     _sShipment.save(this.items, this.$fun.getUserID()).then((r) => {
      //       if (r.status == 200) {
      //         this.subirBk();
      //         this.subirBl();
      //         this.subirDAM();

      //         this.$fun.alert("Registros guardados correctamente", "success");
      //         this.items = {};
      //         this.$emit("closeModal");
      //       }
      //     });
      //   }
      // });
    },

    addNotifier() {
      this.itemsNotifiers.Line = this.dataNotifiersAll.length + 1;
      this.itemsNotifiers.EsnID = 0;
      this.dataNotifiersAll.push(this.itemsNotifiers);
      console.log("todos los  notificadores", this.dataNotifiersAll);
      // }
      this.$fun.alert("Notificante agregado", "success");
      this.itemsNotifiers = {};
    },

    subirBk() {
      if (this.fileBk != null) {
        var formData = new FormData();
        formData.append("file", this.fileBk);
        _sHelper
          .uploadfile(1, this.$fun.getUserID(), formData, 6)
          .then((response) => {
            if (response.status == 200) {
              this.fileBk = [];
              return;
            }
          });
      } else {
        this.$fun.alert("Sin datos que subir");
      }
    },

    subirBl() {
      if (this.fileBl != null) {
        var formData = new FormData();
        formData.append("file", this.fileBl);
        _sHelper
          .uploadfile(1, this.$fun.getUserID(), formData, 7)
          .then((response) => {
            if (response.status == 200) {
              this.fileBl = [];
              return;
            }
          });
      } else {
        this.$fun.alert("Sin datos que subir");
      }
    },

    subirDAM() {
      if (this.fileDAM != null) {
        var formData = new FormData();
        formData.append("file", this.fileDAM);
        _sHelper
          .uploadfile(1, this.$fun.getUserID(), formData, 8)
          .then((response) => {
            if (response.status == 200) {
              this.fileDAM = [];
              return;
            }
          });
      } else {
        this.$fun.alert("Sin datos que subir");
      }
    },

    orderDetails() {
      let i = 0;
      this.dataNotifiersAll.map((e) => {
        i++;
        e.Line = i;
      });
    },

    removeItem(item) {
      if (this.dataNotifiersAll.length > 0) {
        if (item.EsnID > 0) {
          this.itemsRemoved.push(
            this.dataNotifiersAll.filter((x) => x.Line == item.Line)[0]
          );
          this.itemsRemoved.forEach((element) => {
            element.SecStatus = 0;
          });
        }

        this.dataNotifiersAll = this.dataNotifiersAll.filter(
          (x) => x.Line != item.Line
        );
        this.orderDetails();
      }
    },

    isValidated() {
      if (this.itemsData.length == 0) {
        this.validate = false;
        this.message = "Debe buscar un OV valida";
      }
      if (this.dataNotifiersAll.length == 0) {
        this.validate = false;
        this.message = "Debe agregar mínimo un notificante";
      }

      // validando  que todos los campos sean obligatorios
      for (let elements in this.items) {
        console.log(elements);
      }
    },
  },
};
</script>
